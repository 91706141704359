import gql from 'graphql-tag'

export default gql`{
  id
  address
  age
  areasInterest
  basicSalary
  belongsTo
  city
  dateBirth
  email
  entities
  formation {
    course
    level
    status
    institution
    startDate
    endDate
    year
  }
  fullName
  genre
  idealSalary
  languages {
    name
    level
  }
  lastName
  media {
    facebook
    linkedin
    twitter
  }
  name
  nationality
  newCreated
  otherSkills
  photo
  presentation
  profession
  professionalExperience {
    entity
    description
    office
    startDate
    endDate
  }
  references {
    fullName
    office
    telephone
  }
  status
  telephone
  telephone2
}
`